import './index.scss';
import './responsive.scss';
import Router from './router';
import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const GlobalStateProvider = React.lazy(() => import('./service/global-state'))


function App() {

  return (
    <GlobalStateProvider>
      <div className="App">
        <Router />
        <ToastContainer autoClose={3000} />
      </div>
    </GlobalStateProvider>
  );
}

export default App;
